.rule {
    header {
        width: 100%;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 46px;

        p {
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .ant-table-thead {
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        text-align: right;
        letter-spacing: 0px;
        color: #3E454D;
    }

    .ant-table-tbody {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #666666;
    }
}