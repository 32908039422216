.login-container {
	display: flex;
	align-items: center;
	width: 1920px;
	height: 1080px;
	background-size: 100% 100%;
	position: relative;

	img {
		position: absolute;
		top: 142px;
		left: 215px;
		width: 225.38px;
		height: 74px;
	}

	.logins {
		width: 580px;
		min-height: 400px;
		max-height: 774px;
		background-color: red;
		background: #FFFFFF;
		box-shadow: 0px 8px 35px 6px rgba(22, 48, 102, 0.08);
		border-radius: 28px;
		position: absolute;
		right: 14%;
	}

	h1 {
		text-align: center;
		margin: 34px 0 43px 0;
		font-family: PingFangSC-Semibold;
		font-size: 28px;
		color: #4E5C68;
		letter-spacing: 0;
		font-weight: 600;

		p {
			margin: 0;
			font-family: PingFangSC-Semibold;
			font-size: 18px;
			color: #B1BFCC;
			letter-spacing: 0;
			font-weight: 600;
		}
	}

	.ant-form-item {
		display: flex;
		justify-content: center;
	}

	header {
		height: 40px;
		text-align: center;
		font-family: PingFangSC-Semibold;
		font-size: 28px;
		color: #4E5C68;
		letter-spacing: 0;
		font-weight: 600;
		margin: 15px 0;
	}

	footer {
		text-align: center;
		margin-bottom: 30px;

		span {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #2078FF;
			line-height: 20px;
			font-weight: 400;
		}
	}

	.Tabs {
		border-radius: 10.6px;
		transition: unset;
		padding-bottom: 25px;

		.ant-tabs-tab {
			font-family: PingFangSC-Semibold;
			font-size: 28px;
			color: #1F78FF;
			letter-spacing: 0;
			font-weight: 600;
		}

		.ant-form-item-margin-offset {
			margin-bottom: 0 !important;
		}

		.ant-row {
			display: flex;
			flex-flow: row wrap;
			width: 460px;
			height: 58px;
			background: #F6F9FF;
			border-radius: 28px;
			align-items: center;
			padding: 0 14px;
		}

		.ant-input {
			border: none;
			background: none;
			font-size: 18px !important;
		}

		.ant-input-affix-wrapper {
			border: none;
			background: none
		}

		.ant-form label {
			font-size: 0px !important;
		}

		.ant-space-item {
			.ant-btn {
				font-size: 18px;
			}
		}

		.ant-select-selection-item {
			text-align: left;
			font-size: 18px;
		}

		.name {

			.ant-row {
				background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
				box-shadow: 0 4px 16px 0 rgba(2, 84, 207, 0.20);
				border-radius: 29px;
			}

			.ant-btn {
				width: 25.3125rem;
				height: 3.625rem;
				background: none;
				border: none;
				font-family: PingFangSC-Medium;
				font-size: 20px;
				color: #FFFFFF;
				font-weight: 500;
			}
		}
	}

	.anniu-fasong-yzms {
		position: absolute;
		right: 26%;
		bottom: 66%;
		width: 90px;
		height: 25px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1F78FF;
		font-weight: 500;
	}

	.ant-space {
		gap: 65px !important;
	}

	.wangJi {
		margin: 0 auto;
		width: 28.75rem;
		height: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #666666;
		font-weight: 400;
	}

	.ant-form-item-control-input-content {
		flex: inherit !important;
		max-width: 100%;
	}

	.ant-form-item-control-input-content {
		width: 100%;
	}

	.ant-select-selector {
		border: none !important;
		background: none !important;
	}

	.ant-select-selection-placeholder {
		text-align: left;
		font-size: 18px;
	}

	.ant-tabs-content-holder {
		padding-top: 10px;
	}

	.ant-form-item-control {
		margin: 0 auto;
		text-align: center;
	}

	.xieYi {
		display: flex;
		padding-left: 72px;
		align-items: center;
		font-size: 17px;

		p {
			margin: 0;
			margin-left: 1%;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #666666;
			font-weight: 400;

			span {
				color: #19A3F6;
			}
		}
	}

}

.dataScreen {
	width: 1920px;
	height: 1080px;
	transform-origin: 0 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transition: 0.3s;
}

.layout {
	height: 100%;

	p {
		margin: 0;
	}

	.header {
		padding: 0 25px;
		height: 86px;
		background-color: #1756BC;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.headerImg {
			width: 100px;
			height: 62px;
			display: flex;
			align-items: center;

			img {
				width: 152.29px;
				height: 50px;
			}

			div {
				flex-wrap: wrap;
				color: white;

				p {
					font-size: 30px;
				}

				span {
					white-space: nowrap;
					font-size: 12px;
				}
			}
		}

		.tuiChu {
			width: 120px;
			height: 48px;
			background: #FFFFFF;
			border-radius: 27.5px;
			font-family: PingFangSC-Semibold;
			font-size: 18px;
			color: #1756BC;
			letter-spacing: 0.66px;
			font-weight: 600;
			text-align: center;
			line-height: 48px;
		}
	}

	.layout {
		height: 90%;
		padding: 41px 160px;
	}
}



.ant-layout-sider {
	margin-right: 34px !important;
	width: 312px !important;
	background: #FFFFFF !important;
	box-shadow: 8px 0px 20px 0px rgba(227, 227, 227, 0.5) !important;
	border-radius: 16px !important;
	flex: 0 0 312px;
	max-width: 312px;
	min-width: 312px;

	.ant-menu {
		background: #FFFFFF !important;
		border-radius: 16px !important;
	}

	.ant-menu-title-content {
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #7D91AA;
		letter-spacing: 0;
		font-weight: 400;
	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: #edf4ff !important;
		color: #318bdd;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0;
		border-radius: 8px
	}

	.ant-menu-submenu-selected>.ant-menu-submenu-title {
		background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
		border-radius: 8px;
	}

	.ant-menu-item-selected {
		background-color: rgb(4, 77, 92) !important;
		color: red !important;
	}

	.ant-menu.ant-menu-dark{
		padding: 0 20px !important;
	}
	.anticon svg {
		display: inline-block;
		color: #7D91AA;
	}
}

.ant-layout.ant-layout-has-sider>.ant-layout {
	background: #FFFFFF !important;
	box-shadow: 8px 0px 20px 0px rgba(227, 227, 227, 0.5) !important;
	border-radius: 16px !important;
	padding: 40px;
	overflow: auto;
}

.ant-tabs-nav {
	margin-top: 68px !important;
}

.register {
	.ant-tabs-nav-wrap {
		justify-content: center;

		.ant-tabs-tab {
			font-family: PingFangSC-Regular;
			font-size: 22px;
			color: #4E5C68;
			letter-spacing: 0;
			font-weight: 400;
		}

		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: PingFangSC-Semibold;
			font-size: 24px;
			color: #1F78FF;
			letter-spacing: 0;
			font-weight: 600;
		}

		.ant-tabs-ink-bar {
			background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
			border-radius: 3px;
			height: 6px !important;
		}

		.ant-tabs-nav-wrap {
			position: unset !important;
		}
	}
}