.allCourses {

.filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid rgba(246, 246, 246, 1);
    border-top: 1px solid rgba(246, 246, 246, 1);

    .p {
        width: 50%;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #3E454D;
        letter-spacing: 0;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 29.5px 0;

        .ant-input-affix-wrapper-lg {
            width: 300px;
            height: 48px;
            background: #FFFFFF;
            border: 1px solid rgba(214, 221, 228, 1);
            border-radius: 24px;
        }

        .ant-select-selector {
            height: 48px;
            background: #FFFFFF;
            border: 1px solid rgba(214, 221, 228, 1);
            border-radius: 24px;

            .ant-select-selection-item {
                line-height: 48px;
                font-size: 16px;
                color: #868EBE;
            }
        }

        .ant-btn-round.ant-btn-lg {
            height: 48px;
            padding: 6.4px 20px;
            font-size: 18px;
            border-radius: 40px;
            background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
            border-radius: 24px;
            width: 120px;
        }
    }

    .p:nth-child(4) {
        width: 50%;
        justify-content: space-evenly;
    }
}

.tongJi {
    margin-top: 29.5px;
    width: 35%;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #3E454D;
    letter-spacing: 0;
    text-align: right;
    line-height: 28px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #2077FF;
        letter-spacing: 0;
        text-align: right;
        line-height: 28px;
        font-weight: 400;
    }
}

main {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div {
        height: 282px;
        width: 271px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px 0px rgba(39, 71, 110, 0.1);
        border-radius: 12px;
        margin-top: 40px;

        .divHeader {
            background: none;
            box-shadow: none;
            border: none;
            height: 50%;
            margin: 0;

            video {
                width: 100%;
                height: 100%;
            }
        }

        .divFooter {
            background: none;
            border: none;
            box-shadow: none;
            height: 50%;
            margin: 0;

            p:nth-child(2) {
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: PingFangSC-Regular;
                    font-size: 16px;
                    color: #1F78FE;
                    letter-spacing: 0;
                    font-weight: 400;
                    cursor: pointer;
                }

                .ant-checkbox-wrapper {
                    width: 20px;
                    height: 20px;
                    margin-left: 150px;

                    .ant-checkbox {
                        width: 20px;
                    }
                }

                .ant-checkbox-inner {
                    width: 20px;
                    height: 20px;
                }
            }

            p:nth-child(1) {
                width: 80%;
                margin: 0 auto;
                height: 60%;
                font-family: PingFangSC-Medium;
                font-size: 18px;
                color: #3E454D;
                letter-spacing: 0;
                line-height: 28px;
                font-weight: 500;
                text-align: center;
                line-height: 74px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
}

footer {
    padding: 60px 0;
    text-align: right;
}
}

.reviewWorks {
    header {
        width: 100%;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .ant-form-item-control-input-content {
        justify-content: space-between;
    }

    .ant-input-disabled {
        border-radius: 8px !important;
    }

    .ant-select-selector {
        height: 58px !important;
        background: #F6F9FF;
        border-radius: 8px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #666666;
        font-weight: 400;

        .ant-select-selection-item {
            text-align: initial;
        }
    }

    .ant-form-item-label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .ant-form-item .ant-form-item-label>label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
    }

    .ant-input {
        height: 58px !important;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #999999;
        font-weight: 400;
    }

    .ant-select-single {
        height: 58px !important;
    }

    .ant-form-item-control {
        justify-content: center !important;
    }

    .ant-form-item {
        max-width: none !important;
    }

    .video {
        width: 440px;
        height: 239px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        span {
            width: 90% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            white-space: nowrap;
        }
    }

    .videos {
        width: 440px;
        height: 239px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        span {
            width: 90% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            white-space: nowrap;
        }

        video::-webkit-media-controls-timeline {
            display: none;
        }
    }

    .video:nth-child(1) {
        margin-right: 80px;
    }

    .fujian {
        padding-top: 20px;

        p {
            margin-bottom: 16px;

            a {
                TEXT-DECORATION: none;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.88);
                line-height: 22px;
                font-weight: 400;
            }

            .icon {
                opacity: 0.88;
                color: #1F7AFE;
            }
        }
    }

    .ant-input-number-input {
        background: #FFFFFF;
        border: 1px solid rgba(219, 219, 219, 1);
        border-radius: 8px;
        height: 58px;
        width: 600px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #999999;
        font-weight: 400;
    }

    .ant-input-number {
        border: none;
    }

    .queDing {
        width: 142px;
        height: 48px;
        background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
        border-radius: 27.5px;
        margin: 30px 0;
    }

    .quXiao {
        width: 142px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #1F79FF;
        border-radius: 27.5px;
        margin: 30px 0;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #1F79FF;
        letter-spacing: 0.66px;
        font-weight: 400;
    }

    .ant-form-item-control-input-content {
        display: flex;
        flex-wrap: wrap;
    }

    .daFen {
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            width: 23%;
            white-space: nowrap;
            margin-right: 10px;
            text-align: right;
        }

        .ant-input-number-input {
            width: 160px;
        }

        .ant-input-number-group-addon {
            background: none;
            border: none;
        }
    }
}